import "./App.css";
import "./all.css";
import AnimatedVideo from "../../components/AnimatedVideo/index.js";
import Reel from "../../components/Reel/index.js";
import WhoWeAre from "../../components/WhoWeAre/index.js";
import OurBusiness from "../../components/OurBusiness/index.js";
import HomeWorks from "../../components/HomeWorks/index.js";
import OurClients from "../../components/OurClients/index.js";
import News from "../../components/News/index.js";
import Other from "../../components/Other/index.js";
import HomeGear from "../../components/HomeGear/index.js";
import HomeInfo from "../../components/HomeInfo/index.js";
import Footer from "../../components/Footer/index.js";
import $ from "jquery";

function HomePage() {
	$(document).ready(function () {
		$("#siteWrapper").appendTo(".app");
		const handleScrollToHash = () => {
			if (window.location.hash) {
				setTimeout(() => {
					const element = document.getElementById(window.location.hash.slice(1));
					if (element) {
						const top = element.getBoundingClientRect().top + window.pageYOffset - 23;
						window.scrollTo({
							top: top,
							behavior: "smooth",
						});
					}
				}, 100);
			}
		};

		// Run the scroll function on component mount
		handleScrollToHash();

		// Optionally, you can add an event listener for hash changes
		window.addEventListener("hashchange", handleScrollToHash);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener("hashchange", handleScrollToHash);
		};
	});

	return (
		<>
			<AnimatedVideo></AnimatedVideo>
			<Reel></Reel>
			<WhoWeAre></WhoWeAre>
			<OurBusiness></OurBusiness>
			<HomeWorks></HomeWorks>
			<OurClients></OurClients>
			<News></News>
			<Other></Other>
			<HomeGear></HomeGear>
			<HomeInfo></HomeInfo>
			<Footer></Footer>
		</>
	);
}

export default HomePage;
