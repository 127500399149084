/* eslint-disable no-unused-vars */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {useTranslation} from "react-i18next";
const foundersSrc = "https://dimgrey-bison-178310.hostingersite.com/eggworm/shared-assets/founders.png";
const joinUsSrc = "https://dimgrey-bison-178310.hostingersite.com/eggworm/shared-assets/joinUs.png";

function Other() {
	const {i18n} = useTranslation();
	const currentLanguage = i18n.language;
	const isJapanese = currentLanguage === "jp";

	return (
		<section className="home-other">
			<div className="home-other-item">
				<a href={isJapanese ? "/jp/team" : "/en/team"} className="">
					<div className="home-other-item__image">
						<img data-src={foundersSrc} src={foundersSrc} lazy="loaded" className="loaded"></img>
					</div>
					<div className="home-other-item__cover"></div>
					<div className="home-other-item__title">
						TEAM<span></span>
					</div>
				</a>
			</div>
			<div className="home-other-item">
				<a href="/contact/?joinus=1" className="">
					<div className="home-other-item__image">
						<img data-src={joinUsSrc} src={joinUsSrc} lazy="loaded" className="loaded"></img>
					</div>
					<div className="home-other-item__cover"></div>
					<div className="home-other-item__title">
						JOIN US<span></span>
					</div>
				</a>
			</div>
		</section>
	);
}

export default Other;
