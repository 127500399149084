/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import "./index.css";
import { useTranslation } from "react-i18next";
import $ from "jquery";

function AnimatedVideo() {
	const playerRef = useRef(null); // create a reference to the player
	const [playing, setPlaying] = useState(true);
	const [isMuted, setIsMuted] = useState(true);
	const [videoFinish, setVideoFinish] = useState(false);
	const [language, setLanguage] = useState(localStorage.getItem("language") || "en");
	const { i18n } = useTranslation();
	const [isActiveMenu, setIsActiveMenu] = useState(false);
	const videoUrl = language === "en" ? "https://player.vimeo.com/video/1010114081" : "https://player.vimeo.com/video/830524731";

	const handleClick = () => {
		$("body").toggleClass("position-fixed");
		const menuOverlay = $(".menu-overlay");
		menuOverlay.toggleClass("active");
		menuOverlay.toggleClass("motion");
		const menu = $(".menu");
		menu.toggleClass("active");
		menu.toggleClass("motion");
		setIsActiveMenu(!isActiveMenu);
	};

	function handleEnded(event) {
		if (language === "en") {
			if (event.playedSeconds >= 62) {
				setPlaying(false); // set playing state to false
				setVideoFinish(true);
			}
		} else {
			if (event.playedSeconds >= 4.5) {
				setPlaying(false); // set playing state to false
				setVideoFinish(true);
			}
		}
	}

	const handleLanguageChange = () => {
		const newLanguage = language === "en" ? "jp" : "en";
		setLanguage(newLanguage);
		localStorage.setItem("language", newLanguage);
		i18n.changeLanguage(newLanguage);
		setPlaying(true);
		setVideoFinish(false);
	};

	useEffect(() => {
		if (!videoFinish) {
			setPlaying(true);
			setVideoFinish(false);
		}
	}, [videoFinish, playing]);

	const scrollToID = (id) => {
		handleClick();
		$("html, body").animate(
			{
				scrollTop: $(id).offset().top,
			},
			1000,
		);
	};

	const toggleMute = (event) => {
		event.stopPropagation();
		const player = playerRef.current;
		if (player) {
			setIsMuted(!isMuted);
			player.getInternalPlayer().setVolume(isMuted ? 1 : 0);
		}
	};

	return (
		<div className="relative-pos">
			<ReactPlayer ref={playerRef} playing={playing} onProgress={handleEnded} className="VideoPlayer backBg" muted={isMuted} url={videoUrl} playsinline></ReactPlayer>
			{language === "en" && (
				<div className="custom-controls">
					<label className="volume">
						<input type="checkbox" onClick={toggleMute} checked={isMuted} />
						<svg viewBox="0 0 108 96">
							<path d="M7,28 L35,28 L35,28 L59,8 L59,88 L35,68 L7,68 C4.790861,68 3,66.209139 3,64 L3,32 C3,29.790861 4.790861,28 7,28 Z"></path>
							<path d="M79,62 C83,57.3333333 85,52.6666667 85,48 C85,43.3333333 83,38.6666667 79,34 L49,3"></path>
							<path d="M95,69 C101.666667,61.6666667 105,54.3333333 105,47 C105,39.6666667 101.666667,32.3333333 95,25 L75.5,6 L49,33"></path>
						</svg>
					</label>
				</div>
			)}
			<div className="switch-wrapper">
				<div className="switch">
					<input id="language-toggle" className="check-toggle check-toggle-round-flat" type="checkbox" defaultChecked={language === "jp"} onClick={handleLanguageChange}></input>
					<label htmlFor="language-toggle"></label>
					<span className="on">EN</span>
					<span className="off">JP</span>
				</div>
			</div>
			<div id="hamburgerMenu" onClick={handleClick} className={`hamburger-button ${isActiveMenu ? "active" : ""}`}>
				<span></span>
				<span></span>
			</div>
			<div class="menu-wrapper">
				<div class="menu-overlay"></div>
				<div class="menu">
					<div class="menu-inner">
						<div class="menu-bg"></div>
						<div class="menu-logo"></div>
						<ul class="footer-menu">
							<li class="footer-menu__item">
								<a class="" onClick={() => scrollToID("#who-we-are")}>
									WHO WE ARE
								</a>
							</li>
							<li class="footer-menu__item ">
								<a class="" onClick={() => scrollToID("#our-services")}>
									OUR SERVICES
								</a>
							</li>
							<li class="footer-menu__item ">
								<a class="" onClick={() => scrollToID("#our-work")}>
									WORKS
								</a>
							</li>
							<li class="footer-menu__item ">
								<a href="https://www.eggworm.jp/contact" class="">
									CONTACT
								</a>
							</li>
						</ul>
						<div class="footer__copyright">
							© <span class="footer__year">2024</span> Eggworm inc. All Rights Reserved.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AnimatedVideo;
