export const news = [
	{
		title: "Sebastian Mair, Interview",
		date: new Date("2024-09-25T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/sebastian-mair-interview",
		img: "https://images.squarespace-cdn.com/content/v1/6465baf2d00ad7078486fd8f/1726819327679-KOISEZCQLBABMZVJCXDM/L1007701.JPG+%281%29.jpg",
		language: ["en"],
	},
	{
		title: "Sebastian Mair, Interview",
		date: new Date("2024-09-25T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/sebastian-mair",
		img: "https://images.squarespace-cdn.com/content/v1/6465baf2d00ad7078486fd8f/1726819327679-KOISEZCQLBABMZVJCXDM/L1007701.JPG+%281%29.jpg",
		language: ["jp"],
	},
	{
		title: "Doc Martin, Interview",
		date: new Date("2024-09-20T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/doc-martin-interview",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1726121198352-AQO6RC5OQW1IOP28KV1X/%40bellaguera-docpressphotos-07878.jpg?format=2500w",
		language: ["en"],
	},
	{
		title: "Doc Martin, Interview",
		date: new Date("2024-09-20T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/doc-martin",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1726121198352-AQO6RC5OQW1IOP28KV1X/%40bellaguera-docpressphotos-07878.jpg?format=2500w",
		language: ["jp"],
	},
	{
		title: "Sofia Ilyas, Interview",
		date: new Date("2024-09-15T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/sofia-ilyas",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1724206288993-CGG2P2DG478A0IL8DFCE/Screen+Shot+2024-08-20+at+17.07.28.png?format=2500w",
		language: ["jp"],
	},
	{
		title: "Sofia Ilyas, Interview",
		date: new Date("2024-09-15T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/sofia-ilyas-interview",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1724206288993-CGG2P2DG478A0IL8DFCE/Screen+Shot+2024-08-20+at+17.07.28.png?format=2500w",
		language: ["en"],
	},
	{
		title: "Anthony Jeselnik: Bones and All Asia Tour",
		date: new Date("2024-08-15T17:00:00.000Z"),
		link: "https://anthonyjeselnik.com/",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1723711505991-P2PU6NY1UY3QJ634M551/b28f7a8b-cd18-4bd5-bf7d-18eee5e1d4b2+%281%29.jpeg?format=2500w",
		language: ["en", "jp"],
	},
	{
		title: "Hiroaki Araki, Interview",
		date: new Date("2024-07-02T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/hiroaki-araki",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1723173399244-S3C84MXD2X9XX9KFPBE0/Screenshot+2024-08-09+at+12.16.14%E2%80%AFPM.png?format=2500w",
		language: ["jp"],
	},
	{
		title: "Hiroaki Araki, Interview",
		date: new Date("2024-07-02T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/hiroaki-araki-interview",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1723173399244-S3C84MXD2X9XX9KFPBE0/Screenshot+2024-08-09+at+12.16.14%E2%80%AFPM.png?format=2500w",
		language: ["en"],
	},
	{
		title: "TIBS 2024: Remy Cointreau Japan",
		date: new Date("2024-05-15T17:00:00.000Z"),
		link: "http://tokyobarshow.com/",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1718330933687-J5TYPO68WH7T5XDRO0FI/Remy+cointreau+tokyo+bar+show.png?format=1500w",
		language: ["jp"],
	},
	{
		title: "Alex From Tokyo, Interview",
		date: new Date("2024-05-01T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/alex-from-tokyo",
		img: "https://images.squarespace-cdn.com/content/v1/6465baf2d00ad7078486fd8f/1718340932866-J20NKOEGXAZ0O4FCCYDT/Alex+from+tokyo.png?format=300w",
		language: ["jp"],
	},
	{
		title: "Atsuko Okatsuka Full Grown Tour Asia",
		date: new Date("2024-03-24T17:00:00.000Z"),
		link: "https://atsukocomedy.com/",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1717559894207-Z98LGLJLO0EKYKK6YAAF/20240322-006.jpg?format=2500w",
		language: ["en"],
	},
	{
		title: "Atsuko Okatsuka Full Grown Tour Asia",
		date: new Date("2024-03-24T17:00:00.000Z"),
		link: "https://atsukocomedy.com/",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1717559894207-Z98LGLJLO0EKYKK6YAAF/20240322-006.jpg?format=2500w",
		language: ["jp"],
	},
	{
		title: "Yosuke Inoue, Interview",
		date: new Date("2024-03-22T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/otai-record-yosukeinoue",
		img: "https://pbs.twimg.com/media/FwoIpUxakAAcJcU?format=jpg&name=medium",
		language: ["jp"],
	},
	{
		title: "Alejandro M. Lopez, Interview",
		date: new Date("2024-03-21T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/alejandro-m-lopez-interview",
		img: "https://images.squarespace-cdn.com/content/v1/6465baf2d00ad7078486fd8f/1711521504310-C0MYARTD286HPX5MQ5YY/ALEJANDRO.jpeg?format=500w",
		language: ["jp"],
	},
	{
		title: "Kristian Riis, Interview",
		date: new Date("2024-03-20T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/Kristian-Riis-Interview",
		img: "https://images.squarespace-cdn.com/content/v1/6465baf2d00ad7078486fd8f/1709248549849-3TI83M0HEGHZGFF73H86/IMG_0132.jpeg?format=500w",
		language: ["jp"],
	},
	{
		title: "Lia Tee, Interview",
		date: new Date("2024-02-25T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/liatee-interview",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1707113196804-47P0KJ720D563A6BPACA/LIA.TK01.jpeg?format=1500w",
		language: ["jp"],
	},
	{
		title: "Lia Tee, Interview",
		date: new Date("2024-02-24T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/lia-tee-interview",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1707113196804-47P0KJ720D563A6BPACA/LIA.TK01.jpeg?format=1500w",
		language: ["en"],
	},
	{
		title: "Eggworm's Dance with the Dragon",
		date: new Date("2024-01-24T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/eggworms-dance-with-the-dragon",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1705821026247-YJ5ZLVJBPZP46I2FQAEV/dragon2024.png?format=2500w",
		language: ["en", "jp"],
	},
	{
		title: "Shain Shapiro, Interview",
		date: new Date("2023-12-28T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/shain-shapiro-interview",
		img: "https://images.squarespace-cdn.com/content/v1/6465baf2d00ad7078486fd8f/1702025527799-WJ2M3XDLYNV7PSPOGYD7/Shain+Shapiro+3.png?format=500w",
		language: ["en"],
	},
	{
		title: "Kenta Suzuki, Interview",
		date: new Date("2024-01-04T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/kenta-suzuki-interview",
		img: "https://images.squarespace-cdn.com/content/v1/6465baf2d00ad7078486fd8f/1704892467290-USMIC0XHJWKL7LCCOSW2/Screenshot+2024-01-10+214801.png",
		language: ["jp"],
	},
	{
		title: "Anton Wormann, Interview",
		date: new Date("2023-11-18T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/anton-wormann-interview",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1700099240928-AQ0KE9673HTXFT18717G/image0.jpeg?format=2500w",
		language: ["jp"],
	},
	{
		title: "Anton Wormann Interview",
		date: new Date("2023-11-18T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/anton-wormann-interview",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1700099240928-AQ0KE9673HTXFT18717G/image0.jpeg?format=2500w",
		language: ["en"],
	},
	{
		title: "Holly Graham, Interview",
		date: new Date("2023-11-03T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/holly-graham-interview",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1698828548618-41B9Z4Z4ABS4Y60VXMCX/Tokyo+Confidential+-+Holly+Graham+2+%28credit+Millie+Tang%29.jpg?format=2500w",
		language: ["jp"],
	},
	{
		title: "Holly Graham, Interview",
		date: new Date("2023-11-03T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/holly-graham-interview",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1698828548618-41B9Z4Z4ABS4Y60VXMCX/Tokyo+Confidential+-+Holly+Graham+2+%28credit+Millie+Tang%29.jpg?format=2500w",
		language: ["en"],
	},
	{
		title: "Tommy Guerrero, Interview",
		date: new Date("2023-10-24T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/tommy-guerrero-interview",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1697765370667-OWJ03S1DRFA81UF8LOR3/Tommy+cover+2.png?format=2500w",
		language: ["jp"],
	},
	{
		title: "Tommy Guerrero, Interview",
		date: new Date("2023-10-24T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/tommy-guerrero-interview",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1697765370667-OWJ03S1DRFA81UF8LOR3/Tommy+cover+2.png?format=2500w",
		language: ["en"],
	},
	{
		title: "James Lavelle, Interview",
		date: new Date("2023-10-23T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/james-lavelle-interview",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1700292392472-6D7W54V04XNTRYPH7JIS/james+3.png?format=2500w",
		language: ["en", "jp"],
	},
	{
		title: "EJ 003 - Acquire the Knowledge",
		date: new Date("2023-10-22T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/enter-japan-003-acquire-the-knowledge",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1692103377659-LK5RTXFNOFMOPJA2GGB4/acquiretheknowledge.jpeg?format=1000w",
		language: ["en"],
	},
	{
		title: "Astrid Klein, Interview",
		date: new Date("2023-10-21T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/astrid-klein-interview",
		img: "https://images.squarespace-cdn.com/content/v1/6465baf2d00ad7078486fd8f/1696547982414-JDQK2ZZF7NYCB1NQ7IVI/Thumbnal+astrid.png?format=500w",
		language: ["jp"],
	},
	{
		title: "Alexandrion Group, Launch",
		date: new Date("2023-10-20T17:00:00.000Z"),
		link: "https://alexandrionwinesandspirits.gr/",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1695800689342-I2CLP5D4TXWWMGT7GNX1/Screenshot+2023-09-27+094414.png?format=2500w",
		language: ["en", "jp"],
	},
	{
		title: "Jimmy Carr, Asia Launch",
		date: new Date("2023-07-31T17:00:00.000Z"),
		link: "https://www.jimmycarr.com/",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1692101450203-KQJPN2DRUCXDGPLBVIWU/jimmycarr.png",
		language: ["en", "jp"],
	},
	{
		title: "Nick Stevenson, Interview",
		date: new Date("2023-09-28T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/nickstevensoninterview",
		img: "https://images.squarespace-cdn.com/content/v1/6465baf2d00ad7078486fd8f/1692965068410-3HMSZ8025X2CBB2P2JBM/Screenshot+2023-07-11+at+13.56.11.png?format=500w",
		language: ["jp"],
	},
	{
		title: "Nina Kravitz, Interview",
		date: new Date("2023-09-30T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/nina-kravitz-interview",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1695129943128-4BT0F5J8ZHP24OOH60ME/NinaKraviz5_credit_NicholasFols.JPG?format=2500w",
		language: ["en"],
	},
	{
		title: "Nina Kravitz, Interview",
		date: new Date("2023-09-30T17:00:00.000Z"),
		link: "https://www.mixmagjapan.com/posts/nina-kravitz",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1695129943128-4BT0F5J8ZHP24OOH60ME/NinaKraviz5_credit_NicholasFols.JPG?format=2500w",
		language: ["jp"],
	},
	{
		title: "Shima & Co, Launch",
		date: new Date("2023-06-30T17:00:00.000Z"),
		link: "https://shimaokinawa.com/",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1695797442025-YZTAJUWEQUAP51KOTYV8/Screenshot+2023-09-27+084923.png?format=2500w",
		language: ["en", "jp"],
	},
	{
		title: "Game Grumps, Japan Tour",
		date: new Date("2023-06-30T17:00:00.000Z"),
		link: "https://www.youtube.com/user/GameGrumps/videos",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1692101775497-CBXPHF3XXLO4N0ZC3SZJ/gamegrumps.png",
		language: ["en", "jp"],
	},
	{
		title: "EJ 002 - Enter the Right Way",
		date: new Date("2023-06-30T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/enter-japan-002-enter-the-right-way",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1691125477279-PQ19VY8F1576QNP42W3G/Newsletter+2.jpeg",
		language: ["en"],
	},
	{
		title: "Mixmag Website, Relaunch",
		date: new Date("2023-05-31T17:00:00.000Z"),
		link: "https://mixmagjapan.com/",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1689471178131-IEIMQA90UWOC1P44Q4TB/mixmag.png",
		language: ["en", "jp"],
	},
	{
		title: "EJ 001 - Discover Your Opportunity",
		date: new Date("2023-05-31T17:00:00.000Z"),
		link: "https://www.eggworm.jp/thesocial/enter-jp-001",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1689484207047-3ABIUHN3TCY9EQDX3YT7/market.png",
		language: ["en"],
	},
	{
		title: "BTV, Pop-Up",
		date: new Date("2023-05-31T17:00:00.000Z"),
		link: "https://www.fashionsnap.com/article/2023-06-27/beyond-the-vines-popup/",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1692101208318-YR2ZI6E60Q4920K4F4OI/MicroDumpling.png",
		language: ["en", "jp"],
	},
	{
		title: "Add Second House, Strategy & Creative",
		date: new Date("2023-04-30T17:00:00.000Z"),
		link: "https://add-second-house.com/",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1692102215577-Z6OHKZNQRXEH9G4233JZ/ADD.png",
		language: ["en", "jp"],
	},
	{
		title: "BTV, Tokyo Launch",
		date: new Date("2023-02-28T17:00:00.000Z"),
		link: "https://prtimes.jp/main/html/rd/p/000000001.000118291.html",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/7d9041c0-601a-464c-a4e6-6dac2cbcac88/image+%283%29.png",
		language: ["en", "jp"],
	},
	{
		title: "What is Cardano?, Launch",
		date: new Date("2022-12-31T17:00:00.000Z"),
		link: "https://www.youtube.com/watch?v=pPvIYLLF8yI",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1692101694938-VAVUVESQWF1RBEJQJC3F/TokyoCardano.png",
		language: ["en", "jp"],
	},
	{
		title: "Cardano Summit, Launch",
		date: new Date("2022-11-30T17:00:00.000Z"),
		link: "https://www.youtube.com/watch?v=uwq8rdw4z9w",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1692101576113-KZA28UACFNBOELH8U71G/TokyoCardanoSummit.png",
		language: ["en", "jp"],
	},
	{
		title: "Tokyo Cardano, Launch",
		date: new Date("2022-10-31T17:00:00.000Z"),
		link: "https://tokyocardano.com/",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1692101694938-VAVUVESQWF1RBEJQJC3F/TokyoCardano.png",
		language: ["en", "jp"],
	},
	{
		title: "Notion Live, Tokyo & Kyoto",
		date: new Date("2022-10-31T17:00:00.000Z"),
		link: "https://signal.diamond.jp/articles/-/1498",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1692102323711-1VQBPCB47PRGNQW1JDQS/notion.jpeg",
		language: ["en", "jp"],
	},
	{
		title: "BBC, 100 Year Anniversary Event",
		date: new Date("2022-10-31T17:00:00.000Z"),
		link: "https://www.bbc.com/historyofthebbc/bbc-100/",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1692102084743-FF7RC1I6S5F88KLI2SPK/BBC.png",
		language: ["en"],
	},
	{
		title: "Remy Martin, Cocktail Competition",
		date: new Date("2022-09-30T17:00:00.000Z"),
		link: "https://prtimes.jp/main/html/rd/p/000000206.000016452.html",
		img: "https://images.squarespace-cdn.com/content/v1/5eb624a9707ef42c472b42ec/1692102610746-EUTUGFZOGCJVQIF47I40/remymartin.png",
		language: ["en", "jp"],
	},
];
