/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, {useState} from "react";
const ewShop = "https://dimgrey-bison-178310.hostingersite.com/eggworm/shared-assets/ewShop.png";

function HomeGear() {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<section className="home-gear">
			<a  title="EG Swag" target="_blank">
			<div className={`home-gear__image ${isHovered ? "active" : ""}`} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
					<img alt="swag shop" className="pc-only loaded" data-src={ewShop} src={ewShop} lazy="loaded"></img>
					<img alt="swag shop" className="sp-only" data-src={ewShop} src={ewShop} lazy="loading"></img>
				</div>
				<div className="arrow">
					<span></span>
				</div>
			</a>
		</section>
	);
}

export default HomeGear;
